<template>
  <v-container
    fluid
    tag="section"
    class="px-0 px-md-3 abandoned-bucket-container"
  >
    <v-card 
      class="abandoned-bucket-card"
      rounded="lg"
      elevation="3"
    >
      <!-- Abandoned Bucket Header Banner -->
      <div class="abandoned-banner">
        <v-icon color="white" class="mr-2">mdi-package-variant-closed</v-icon>
        <span class="text-subtitle-1 font-weight-medium white--text">Active Bucket</span>
      </div>
      
      <!-- Bucket Information -->
      <v-card-text class="bucket-content">
        <div class="bucket-info">
          <v-row class="info-grid">
            <v-col cols="12" sm="6" md="3">
              <div class="info-cell">
                <div class="info-label">
                  <v-icon small color="primary" class="mr-1">mdi-account</v-icon>
                  User
                </div>
                <div class="info-value">{{ bucket.user_name }}</div>
              </div>
            </v-col>
            
            <v-col cols="12" sm="6" md="3">
              <div class="info-cell">
                <div class="info-label">
                  <v-icon small color="primary" class="mr-1">mdi-clock-start</v-icon>
                  Started at
                </div>
                <div class="info-value">{{ bucket.started_at | DateFilter }}</div>
              </div>
            </v-col>
            
            <v-col cols="12" sm="6" md="3">
              <div class="info-cell">
                <div class="info-label">
                  <v-icon small color="primary" class="mr-1">mdi-update</v-icon>
                  Last updated
                </div>
                <div class="info-value">{{ bucket.last_updated_at | DateFilter }}</div>
              </div>
            </v-col>
            
            <v-col cols="12" sm="6" md="3">
              <div class="info-cell">
                <div class="info-label">
                  <v-icon small color="primary" class="mr-1">mdi-package-variant</v-icon>
                  Parcels
                </div>
                <div class="info-value">{{ bucket.orders.length }}</div>
              </div>
            </v-col>
          </v-row>
        </div>
        
        <!-- Action Buttons -->
        <div class="action-section">
          <v-btn 
            color="success" 
            dark 
            rounded 
            elevation="2"
            x-large
            :loading="loading"
            @click="recover_bucket({hard_refresh: false})"
            class="action-button mb-4"
          >
            <v-icon left>mdi-package-variant-plus</v-icon>
            Claim & Continue
          </v-btn>
          
          <v-btn 
            color="primary" 
            dark 
            rounded 
            elevation="1"
            :loading="loading"
            @click="recover_bucket({hard_refresh: true})"
            class="secondary-button"
          >
            <v-icon left>mdi-restart</v-icon>
            Claim & Restart
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { DateFilter } from '@/utils/filters'

export default {
  props: {
    bucket: {
      type: Object,
      required: true,
    },
  },
  filters: {
    DateFilter,
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async recover_bucket({ hard_refresh }) {
      this.loading = true
      try {
        await this.$store.dispatch('order/recover_bucket', { from_id: this.bucket.user_id })
        await this.$store.dispatch('order/get_orders', { hard_refresh })
      } catch (error) {
        this.$store.commit('app/SET_SNACK_BAR', `Failed to recover bucket: ${error.message}`)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.abandoned-bucket-container {
  max-width: 600px;
  margin: 0 auto;
}

.abandoned-bucket-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  background-color: white;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1) !important;
  }
}

.abandoned-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff9800; /* warning color */
  color: white;
  padding: 16px;
  font-weight: 500;
}

.bucket-content {
  padding: 24px;
}

.bucket-info {
  margin-bottom: 24px;
}

.info-grid {
  background-color: rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  padding: 16px;
  margin: 0;
}

.info-cell {
  padding: 8px;
  text-align: center;
}

.info-label {
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 8px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-value {
  font-size: 1rem;
  font-weight: 500;
}

.action-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
}

.action-button {
  min-width: 200px;
  transition: all 0.2s ease;
  
  &:hover {
    transform: translateY(-2px);
  }
}

.secondary-button {
  min-width: 200px;
  transition: all 0.2s ease;
  
  &:hover {
    transform: translateY(-2px);
  }
}

/* Mobile-specific styles */
@media (max-width: 600px) {
  .bucket-content {
    padding: 16px;
  }
  
  .info-grid {
    padding: 8px;
  }
  
  .info-cell {
    padding: 4px;
  }
}
</style>
