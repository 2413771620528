<template>
  <v-container
    id="fulfill"
    class="fulfill-container pa-0 pa-md-3"
    tag="section"
    fluid
  >
    <!-- Skip Parcel Dialog -->
    <v-dialog
      v-model="dialog"
      max-width="500"
      content-class="skip-dialog"
    >
      <v-card rounded="lg" elevation="3">
        <v-card-title class="dialog-header">
          <div class="d-flex align-center">
            <v-icon color="warning" class="mr-2">mdi-alert-circle</v-icon>
            <span class="text-h6 font-weight-medium">Skip Parcel</span>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="dialog-content">
          <p>Are you sure you wish to skip this parcel? The items will remain unfulfilled, and the order will be blocked. You can manually remove Order Blocks in Logs.</p>
        </v-card-text>
        
        <v-card-actions class="dialog-actions">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="dialog = false"
            class="mr-2"
          >
            Cancel
          </v-btn>
          <v-btn
            color="warning"
            rounded
            elevation="1"
            @click="dropParcel()"
          >
            <v-icon left>mdi-package-variant-remove</v-icon>
            Skip Parcel
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loading State -->
    <div class="loading-container" v-if="loading || fetching_orders">
      <v-progress-circular
        indeterminate
        :size="64"
        width="5"
        color="primary"
        class="mb-4"
      ></v-progress-circular>
      <div class="text-body-1 grey--text text--darken-1">
        {{ fetching_orders ? 'Fetching orders...' : 'Loading...' }}
      </div>
    </div>

    <!-- Item and Parcel Cards -->
    <div v-else-if="!done_with_own_buckets" class="item-line">
      <!-- Item Cards -->
      <transition-group name="fade-list" tag="div" class="item-cards">
        <ItemCard
          v-for="(item, i) in get_pick_order" 
          :key="`pickCard-${i}`"
          :id="`pickCard-${i}`"
          :item="item"
          :active="i === get_current_item"
          :index="i"
          :request_count="request_counts.some(uuid => uuid === item.uuid)"
          @done="doneItem"
          class="mb-4"
        />
      </transition-group>
      
      <!-- Parcel Cards -->
      <transition-group name="fade-list" tag="div" class="parcel-cards">
        <ParcelCard
          v-for="(order, i) in order_bucket"
          :id="`parcelCard-${i}`"
          :key="`parcelCard-${i}`"
          :order="order"
          :index="i"
          :active="i === get_current_parcel_card"
          :loading="loading_parcels.includes(i)"
          :show_manual_shipment="i === show_manual_shipment"
          :force_skip="i === force_skip"
          @done="doneParcel"
          @drop="alertDropParcel(i)"
          @update="updateParcel"
          @scan_barcode="scan_barcode"
          @retry="handle_parcel_card(i, true)"
          class="mb-4"
        />
      </transition-group>
      
      <!-- Return Cards -->
      <transition-group name="fade-list" tag="div" class="return-cards">
        <ReturnCard
          v-for="(item, i) in get_return_items"
          :key="`returnCard-${i}`"
          :id="`returnCard-${i}`"
          :item="item"
          :active="i === returnIndex"
          @done="++returnIndex"
          class="mb-4"
        />
      </transition-group>
    </div>
    
    <!-- Abandoned Buckets -->
    <template v-if="done_with_own_buckets && !done_with_fulfillments">
      <div class="abandoned-buckets">
        <h2 class="text-h5 font-weight-medium text-center mb-4">
          <v-icon color="warning" class="mr-2">mdi-package-variant</v-icon>
          Abandoned Buckets
        </h2>
        
        <transition-group name="fade-list" tag="div">
          <AbandonedBucketsCard
            v-for="(bucket, index) in abandoned_buckets"
            :key="`abandoned-${index}`"
            :bucket="bucket"
            class="mb-4"
          />
        </transition-group>
      </div>
    </template>
    
    <!-- All Done Message -->
    <v-alert
      v-if="done_with_fulfillments"
      type="success"
      rounded="lg"
      elevation="2"
      class="all-done-alert"
    >
      <div class="d-flex align-center">
        <v-icon color="success" size="36" class="mr-3">mdi-check-circle</v-icon>
        <div>
          <div class="text-h6 font-weight-medium">All Done!</div>
          <div>Nothing more to do here. Great job!</div>
        </div>
      </div>
    </v-alert>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ItemCard from '@/components/ItemCard'
import ReturnCard from '@/components/ReturnCard'
import ParcelCard from '@/components/ParcelCard'
import AbandonedBucketsCard from '@/components/AbandonedBucketsCard'
import VueScrollTo from 'vue-scrollto'

export default {
  components: {
    ItemCard,
    ParcelCard,
    ReturnCard,
    AbandonedBucketsCard,
  },
  computed: {
    ...mapGetters(
      'order', ['get_pick_order', 'get_current_item', 'get_current_parcel_card', 'get_return_items']
    ),
    ...mapGetters({
      get_printing_possible: 'warehouse/get_printing_possible',
      get_product: 'product/get_product',
      get_product_inventory_alarm: 'product/get_product_inventory_alarm',
    }),
    ...mapState({
      order_bucket: state => state.order.order_bucket,
      fetching_orders: state => state.order.fetching_orders,
      company: state => state.warehouse.company,
      abandoned_buckets: state => state.order.abandoned_buckets,
      loading_parcels: state => state.order.loading_parcels,
    }),
    done_with_own_buckets(){
      return !this.$store.state.order.did_check_abandoned && !this.$store.state.order.fetching_orders && !this.order_bucket.length
    },
    done_with_fulfillments(){
      return this.$store.state.order.did_check_abandoned && !this.$store.state.order.fetching_orders && !this.order_bucket.length
    }
  },
  watch: {
    done_with_own_buckets: async function(val){
      if(val){
        this.handle_scroll('#fulfill', -60)
        this.loading = true
        await this.$store.dispatch('order/get_abandoned_buckets')
        this.loading = false
      }
    },
    returnIndex: function(val) {
      if(this.loading || val === -1) return // OMG
      if(!this.get_return_items[val]) return this.refresh()
      this.handle_scroll(`#returnCard-${val}`)
    },
    // force_skip: function(){
    //   this.$store.commit('')
    // },  
    get_current_parcel_card: {
      handler: async function(val){
        console.log('parcel card', val);
        if(val === null) return
        if(val === -1) return this.returnIndex = 0
        this.handle_scroll(`#parcelCard-${val}`)
        await this.handle_parcel_card(val)
      },
      immediate: true
    },
    get_current_item: {
      handler: function (val) {
        if(val === -1) return
        this.handle_scroll(`#pickCard-${val}`)
      },
      immediate: true
    }
  },
  data() {
    return {
      loading: true,
      returnIndex: -1,
      show_manual_shipment: null,
      force_skip: null,
      request_counts: [],
      dialog: false,
      parcel_to_drop: null,
    }
  },
  methods: {
    async handle_parcel_card(val, on_retry){
      console.log('handle parcel card')
      if(on_retry){
         this.$store.commit('order/SET_LOADING_PARCEL', { index: val })
         await this.$store.dispatch('order/set_parcel_error', { index: val, reset: true, skip_save: true })
         await this.$store.dispatch('warehouse/request_parcel', { index: val})
         await this.$store.dispatch('warehouse/request_print', val)
         this.$store.commit('order/SET_LOADING_PARCEL', { index: val, done: true })
        }
      if(!this.order_bucket[val].line_items.some(item => item.fulfilled_quantity)){
        this.force_skip = val
        return this.$store.dispatch('order/set_parcel_error', {
          index: val, 
          message: 'No items on this parcel. Skip this parcel.',
          skip_save: true
        })
      } else if(this.order_bucket[val].line_items.some(item => item.removed_quantity && !item.override_parcel_block)
        && !this.company.data_sources.filter(ds => ds.id === this.order_bucket[val].sf_data_source_id)[0].allow_partial_fulfillment
        ) {
        this.force_skip = val
        return this.$store.dispatch('order/set_parcel_error', {
          index: val, 
          message: 'Missing items on this parcel, and store does not allow partial fulfillments. Skip and return this parcel or allow partial fulfillments in Settings.',
          skip_save: true
        })
      } else if(!this.get_printing_possible) {
        return this.$store.commit('app/SET_SNACK_BAR', 'Unable to fetch label')
      } else if (!this.company.carriers.length 
      && !this.company.data_sources.filter(ds => ds.id === this.order_bucket[val].sf_data_source_id)[0].uses_shopify_labels) {
        this.force_skip = val
        return this.$store.dispatch('order/set_parcel_error', {
          index: val, 
          message: 'Shopify Labels is not setup on this store, and no external carriers integrated',
          skip_save: true
        })
      } 
      else if(
        !this.order_bucket[val].shipping_lines.length 
        && !this.order_bucket[val].sf_data.shipping_service
        ){
        this.show_manual_shipment = val
        return await this.$store.dispatch('order/set_parcel_error', {
          index: val, 
          message: 'This order has no shipping method specified. Change in Shopify, or manually add a service below',
          type: 'warning',
          skip_save: true
        })
      } else if(
        !this.order_bucket[val].sf_data.shipping_service
        ){
        this.show_manual_shipment = val
        return await this.$store.dispatch('order/set_parcel_error', {
          index: val, 
          message: `There is no service mapped out for ${this.order_bucket[val].shipping_lines[0].code}
           going to ${this.order_bucket[val].shipping_address.country}. Add one manually below, or set up in Settings to automatically map a service to ${this.order_bucket[val].shipping_lines[0].code} for future orders.`,
          type: 'warning',
          skip_save: true
        })
      } else if (
        this.company.data_sources.filter(ds => ds.id === this.order_bucket[val].sf_data_source_id)[0].uses_shopify_labels
        && !this.order_bucket[val].sf_data.shipping_service.selected_package
      ) {
        this.show_manual_shipment = val
        return 
      }
      this.show_manual_shipment = null
    },
    async scan_barcode({ index, barcode }){
      console.log('scanning', barcode);
      // navigator.vibrate(20)
      const item_index = this.order_bucket[index].line_items.findIndex(item => this.get_product(item.uuid).barcode === barcode)
      if(item_index === -1) return this.$store.commit('app/SET_SNACK_BAR', 'This item is not in this parcel')
      const line_item = this.order_bucket[index].line_items[item_index]
      line_item.is_scanned = true
      this.$store.commit('order/SET_LINE_ITEM', { index, item_index, line_item })
      await this.$store.dispatch('order/save_order_bucket')
    },
    doneItem({ seconds_spent, index }){
      console.log(seconds_spent, index)
      this.$store.dispatch('order/submit_item', { seconds_spent, index})
      // navigator.vibrate(20)
    },
    async on_next_parcel(leaving_index){
      console.log('on nect parcel')
      if(this.order_bucket.length > leaving_index) this.$store.dispatch('warehouse/request_print', leaving_index + 1)
      await this.$store.dispatch('order/set_parcel_error', { index: leaving_index, reset: true })
    },
    async doneParcel({seconds_spent, index }){
      // navigator.vibrate(20)
      if(!this.get_printing_possible){ 
        await this.$store.dispatch('order/check_parcel_fulfilled', index)
      } else {
        await this.$store.dispatch('order/fulfill_parcel', { index, seconds_spent })
        .catch(e => {
          this.$store.dispatch('order/set_parcel_error', {
            index,
            message: `Fulfillment error - do not ship this parcel: ${e.name}: ${e.message}.`
          })
          this.force_skip = index
        })
      }
      this.on_next_parcel(index)
    },
    alertDropParcel(i){
      this.parcel_to_drop = i
      this.dialog = true
    },
    async dropParcel(){
      this.dialog = false
      await this.$store.dispatch('order/block_order', this.parcel_to_drop)
      this.$store.dispatch('order/drop_parcel', this.parcel_to_drop)
      this.on_next_parcel(this.parcel_to_drop)
    },
    async updateParcel({index, ...shipping_service}){
      console.log('shipping_service', shipping_service);
      this.order_bucket[index].sf_data.shipping_service = { 
        ...this.order_bucket[index].sf_data.shipping_service,
        ...shipping_service
      }
      this.$store.commit('order/SET_ORDER_BUCKET', this.order_bucket)
      await this.$store.dispatch('order/save_order_bucket')
      this.show_manual_shipment = null
      await this.$store.dispatch('order/set_parcel_error', { index, reset: true })
      if(!this.order_bucket[index].sf_data.labels || !this.order_bucket[index].sf_data.labels.length){
        this.$store.commit('order/SET_LOADING_PARCEL', { index })
         await this.$store.dispatch('warehouse/request_parcel', { index })
         await this.$store.dispatch('warehouse/request_print', index)
         this.$store.commit('order/SET_LOADING_PARCEL', { index, done: true })
        }
    },
    async refresh(){
      console.log('refresh');
      this.loading = true
      this.returnIndex = -1
      this.force_skip = null
      this.show_manual_shipment = null
      this.request_counts =  []
      await this.$store.dispatch('order/refresh_order_bucket')
      this.refresh_products()
      this.loading = false
    },
    async refresh_products(){
      await this.$store.dispatch('order/check_bucket_inventory')
      this.request_counts = this.get_pick_order.filter(item => {
        return item.last_pick && this.get_product_inventory_alarm(item.uuid)
      }).map(item =>  item.uuid)
    },
    handle_scroll(id, offset = -40){
      console.log('asking scroll to', id)
      const scroll = () => {
        console.log('handled scroll to', id)
        setTimeout( () => this.$nextTick(() => VueScrollTo.scrollTo(id, 500, {
          offset,
        }, 100)
      ))
      }
      this.$nextTick(() => {
        if(document.querySelector(id)) return scroll()
        const interval = setInterval(() => {
          if(!document.querySelector(id)) return
          clearInterval(interval)
          return scroll()
        }, 250)
      })
    }
  },
  async mounted(){
    await this.$store.dispatch('order/get_orders', {company_uid: this.company.id, hard_refresh: false})
    this.refresh_products()
    this.loading = false
  }
}
</script>

<style lang="scss" scoped>
.fulfill-container {
  background-color: #f6f6f7;
  min-height: 100vh;
  padding-bottom: 24px;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  padding: 32px;
  
  .v-progress-circular {
    animation: pulse 2s infinite ease-in-out;
  }
}

.dialog-header {
  background-color: #f9f9fa;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 16px 24px;
}

.dialog-content {
  padding: 24px;
  font-size: 1rem;
}

.dialog-actions {
  padding: 16px 24px;
}

.item-line {
  animation: fadeIn 0.6s ease-out;
}

.item-cards,
.parcel-cards,
.return-cards,
.abandoned-buckets {
  margin-bottom: 16px;
}

.all-done-alert {
  margin: 24px auto;
  max-width: 600px;
  animation: fadeIn 0.8s ease-out;
}

.fade-list-enter-active, 
.fade-list-leave-active {
  transition: all 0.5s;
}

.fade-list-enter, 
.fade-list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.fade-list-move {
  transition: transform 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

/* Mobile-specific styles */
@media (max-width: 600px) {
  .fulfill-container {
    padding: 0;
  }
  
  .dialog-header {
    padding: 12px 16px;
  }
  
  .dialog-content {
    padding: 16px;
  }
  
  .dialog-actions {
    padding: 12px 16px;
  }
  
  .all-done-alert {
    margin: 16px;
  }
}
</style>
