<template>
  <v-container
    fluid
    tag="section"
    class="px-0 px-md-3 return-card-container"
  >
    <v-card 
      class="return-card"
      :class="{ 'active-card': active }"
      :disabled="!active"
      rounded="lg"
      elevation="3"
    >
      <!-- Return Header Banner -->
      <div class="return-banner">
        <v-icon color="white" class="mr-2">mdi-keyboard-return</v-icon>
        <span class="text-subtitle-1 font-weight-medium white--text">Return to Shelf</span>
      </div>
      
      <!-- Item Information -->
      <div class="return-content">
        <div class="item-info">
          <h3 class="text-h6 font-weight-medium text-center mb-4">
            <span class="quantity">{{ item.fulfilled_quantity }}x</span> {{ item.name }}
          </h3>
          
          <!-- Location Information -->
          <div class="location-grid">
            <div class="location-cell">
              <div class="location-label">Shelve</div>
              <div class="location-value">{{ item.sf_position && item.sf_position.shelve }}</div>
            </div>
            <div class="location-cell">
              <div class="location-label">Shelf</div>
              <div class="location-value">{{ (item.sf_position && item.sf_position.shelf) | LetterFilter }}</div>
            </div>
            <div class="location-cell">
              <div class="location-label">Slot</div>
              <div class="location-value">{{ (item.sf_position && item.sf_position.slot) | OneIndexFilter }}</div>
            </div>
            <div class="location-cell">
              <div class="location-label">Product</div>
              <div class="location-value">{{ (item.sf_position && item.sf_position.product) | LetterFilter }}</div>
            </div>
          </div>
        </div>
        
        <!-- Action Button -->
        <div class="action-section">
          <v-btn 
            color="success" 
            dark 
            rounded 
            elevation="2"
            x-large
            @click="$emit('done')"
            class="action-button"
          >
            <v-icon left>mdi-check</v-icon>
            Confirm Return
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { OneIndexFilter, LetterFilter } from '@/utils/filters'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    }
  },
  filters: {
    OneIndexFilter,
    LetterFilter,
  },
  data() {
    return {
      color: '',
    }
  }
}
</script>

<style lang="scss" scoped>
.return-card-container {
  max-width: 600px;
  margin: 0 auto;
}

.return-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  background-color: white;
  
  &.active-card {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1) !important;
  }
}

.return-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff9800; /* warning color */
  color: white;
  padding: 16px;
  font-weight: 500;
  animation: pulse 2s infinite ease-in-out;
}

.return-content {
  padding: 24px;
}

.item-info {
  margin-bottom: 24px;
}

.quantity {
  // color: var(--v-primary-base);
  font-weight: 600;
}

.location-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  padding: 16px;
}

.location-cell {
  padding: 8px;
  min-width: 60px;
}

.location-label {
  font-size: 0.75rem;
  // color: rgba(0, 0, 0, 0.6);
  margin-bottom: 4px;
}

.location-value {
  font-size: 1.75rem;
  font-weight: 500;
}

.action-section {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.action-button {
  min-width: 200px;
  transition: all 0.2s ease;
  
  &:hover {
    transform: translateY(-2px);
  }
}

@keyframes pulse {
  0% {
    background-color: #ff9800;
  }
  50% {
    background-color: #f57c00;
  }
  100% {
    background-color: #ff9800;
  }
}

/* Mobile-specific styles */
@media (max-width: 600px) {
  .return-content {
    padding: 16px;
  }
  
  .location-value {
    font-size: 1.5rem;
  }
}
</style>
