<template>
  <v-container
    fluid
    tag="section"
    class="px-0 px-md-3 parcel-card-container"
  >
    <v-card 
      class="parcel-card"
      :class="{ 'active-card': active }"
      :disabled="!active" 
      rounded="lg"
      elevation="3"
    >
      <!-- Parcel Header -->
      <v-card-title 
        class="parcel-header"
        :style="storeColorStyle"
      >
        <div class="d-flex align-center">
          <v-icon color="primary" class="mr-2">mdi-package-variant</v-icon>
          <span class="text-h6 font-weight-medium parcel-title">
            {{ index | LetterFilter }}: {{ order.name }} - {{ order.shipping_address.name }}
          </span>
        </div>
      </v-card-title>
      
      <v-divider></v-divider>
      
      <!-- Barcode Scanner -->
      <transition name="slide">
        <div v-if="request_barcode_scan" class="scanner-section">
          <StreamBarcodeReader 
            v-if="show_text"
            class="barcode-scanner"
            @loaded="show_text = true"
            @decode="on_scanned_barcode" 
          />
          
          <v-text-field 
            dense
            outlined
            rounded
            label="Barcode"
            v-model="barcode"
            v-if="show_text"
            @input="manual_submit_barcode"
            prepend-inner-icon="mdi-barcode-scan"
            class="barcode-input"
          />
        </div>
      </transition>
      
      <!-- Order Notes -->
      <v-card-text class="parcel-content">
        <!-- Order Note -->
        <v-alert
          v-if="order.note"
          outlined
          dense
          class="order-note mb-4"
        >
          <div class="d-flex align-center">
            <v-icon color="primary" class="mr-2">mdi-note-text</v-icon>
            <span>{{ order.note }}</span>
          </div>
        </v-alert>
        
        <!-- Country Specific Alerts -->
        <v-alert
          type="warning"
          outlined
          dense
          v-if="order.shipping_address.country_code === 'BR'"
          class="mb-4"
        >
          <div class="d-flex align-center">
            <v-icon color="warning" class="mr-2">mdi-alert-circle</v-icon>
            <div>
              <strong>Important:</strong> This order is going to Brazil. CPF number has to be added, or the order should be blocked.
              <ToolTip>CPF is necessary for Brazilian customs. Fulfillment Hero has no way of getting that number from the Shopify API. A store owner needs to manually type the number on the parcel, or you need a system where the store owner copies the number to the order note so you can manually type it out.</ToolTip>
            </div>
          </div>
        </v-alert>
        
        <v-alert
          type="warning"
          outlined
          dense
          v-if="order.shipping_address.country_code === 'CN'"
          class="mb-4"
        >
          <div class="d-flex align-center">
            <v-icon color="warning" class="mr-2">mdi-alert-circle</v-icon>
            <div>
              <strong>Important:</strong> This order is going to China. Resident id number needs to be added, or the order should be blocked.
              <ToolTip>Resident number is necessary for Chinese customs. Fulfillment Hero has no way of getting that number from the Shopify API. A store owner needs to manually type the number on the parcel, or you need a system where the store owner copies the number to the order note so you can manually type it out.</ToolTip>
            </div>
          </div>
        </v-alert>
        
        <!-- Parcel Errors -->
        <ParcelErrorCard
          v-for="(error, i) in (order.errors || [])"
          :key="`error-${i}`"
          :order="order"
          :index="index"
          :error="error"
          @retry="$emit('retry')"
          class="mb-4"
        />
        
        <!-- Shipping Method Selection -->
        <ShippingMapCard 
          v-if="(show_manual_shipment && !store.uses_shopify_labels)"
          :preset_country="order.shipping_address.country_code"
          @save="set_method"
          class="mb-4"
        />
        
        <ShopifyLabelsCard
          v-else-if="show_manual_shipment"
          :order="order"
          :store="store"
          @save="set_method"
          class="mb-4"
        />
        
        <!-- Labels Section -->
        <div
          v-if="order.sf_data.labels && !loading"
          class="labels-section mb-4"
        >
          <div class="text-subtitle-2 font-weight-medium mb-2">
            <v-icon color="primary" class="mr-1">mdi-label</v-icon>
            Labels
          </div>
          
          <div class="labels-grid">
            <v-menu 
              offset-y
              v-for="(label, i) in order.sf_data.labels"
              :key="`print-${i}`"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  rounded
                  outlined
                  color="primary"
                  class="label-btn"
                  v-bind="attrs"
                  v-on="on"
                >
                  <template v-if="label.description.toLowerCase().includes('label')">
                    <span class="tracking-number">#{{ label.tracking_number }}</span>
                  </template>
                  <template v-else>{{ label.description }}</template>
                  <v-icon small right>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              
              <v-list nav dense rounded>
                <v-list-item
                  @click="sendToPrint({pdf_ref: label.ref, description: label.description, media: label.media, })"
                  v-if="company.printserver_id"
                  :disabled="!get_printing_possible || !get_printserver_connected"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-printer-wireless</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>Resend to printer</v-list-item-content>
                </v-list-item>
                
                <v-list-item @click="openPrint(label.ref)">
                  <v-list-item-icon>
                    <v-icon>mdi-open-in-new</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>Open in new tab</v-list-item-content>
                </v-list-item>
                
                <v-list-item @click="printWithDialog(label.ref)">
                  <v-list-item-icon>
                    <v-icon>mdi-printer</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>Print</v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div>
            <ToolTip v-if="order.sf_data.labels.some(label => label.missing_signature)">
              <span class="text-caption">Add your signature in Settings > Users and it will automatically print on the labels</span>
            </ToolTip>
        </div>
        </div>
        
        <!-- Loading State -->
        <div v-else-if="loading && !(order.sf_data.labels || []).length" class="text-center py-4">
          <v-progress-circular
            indeterminate
            color="primary"
            size="32"
            width="3"
          ></v-progress-circular>
          <div class="text-caption grey--text text--darken-1 mt-2">Generating label...</div>
        </div>
        
        <!-- Special Instructions -->
        <div v-if="company.custom_scripts.some(i => i === 'avatar')" class="special-instructions mb-4">
          <v-chip
            v-if="order.sf_data_source_id === '98ca94188af' && order.line_items.some(a => a.product_id === 4297505767517 || a.product_id === 4297503735901)"
            color="secondary"
            outlined
            class="instruction-chip"
          >
            <v-icon left small>mdi-sticker</v-icon>
            {{ new Date().getFullYear() }} Citizen sticker
          </v-chip>
        </div>
        
        <v-chip
          v-if="order.sf_data.shipping_service && ((order.sf_data.shipping_service.addons || []).some(a => a.id === 'SF_STAMPS') || order.sf_data.shipping_service.service_id === 'UX')"
          color="secondary"
          outlined
          class="mb-4 instruction-chip"
        >
          <v-icon left small>mdi-stamp</v-icon>
          Add {{ amount_stamps }}x stamps
        </v-chip>
        
        <!-- Packing Instructions -->
        <div v-if="store && activePackingInstructions.length > 0" class="packing-instructions-section mb-4">
          <div class="text-subtitle-2 font-weight-medium mb-2">
            <v-icon color="primary" class="mr-1">mdi-clipboard-text</v-icon>
            Packing Instructions
          </div>
          
          <v-card outlined rounded="lg" class="packing-instructions-card">
            <v-card-text class="py-2">
              <v-list dense>
                <v-list-item v-for="(instruction, index) in activePackingInstructions" :key="index">
                  <v-list-item-icon>
                    <v-icon small color="primary">{{ 'mdi-chevron-right'}}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ instruction.text }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </div>
        
        <!-- Item List -->
        <div class="items-section mb-4">
          <div class="text-subtitle-2 font-weight-medium mb-2">
            <v-icon color="primary" class="mr-1">mdi-package-variant-closed</v-icon>
            Items
          </div>
          
          <v-card outlined rounded="lg" class="items-card">
            <v-list dense>
              <v-list-item 
                v-for="(item, item_i) in order.line_items.filter(item => item.fulfillable_quantity)" 
                :key="`item-${item_i}`" 
                :class="{'text-decoration-line-through': !item.fulfilled_quantity}"
              >
                <v-list-item-icon v-if="get_product(item.uuid) && get_product(item.uuid).barcode && company.require_item_scanning && item.fulfilled_quantity">
                  <v-icon :color="item.is_scanned ? 'success' : 'grey'">mdi-barcode-scan</v-icon>
                </v-list-item-icon>
                
                <v-list-item-content>
                  <v-list-item-title class="item__title">
                    <span v-if="item.removed_quantity && item.fulfilled_quantity" class="text-decoration-line-through mr-2">{{ item.fulfillable_quantity }}</span>
                    <strong>{{ item.fulfilled_quantity }}x</strong> {{ item.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </div>
        
        <!-- Parcel Photo -->
        <ParcelPhoto 
          v-if="!request_barcode_scan && active && company.require_fulfillment_photos"
          :order_index="index"
          class="mb-4"
        />
      </v-card-text>
      
      <!-- Action Buttons -->
      <v-card-actions class="parcel-actions">
        <v-spacer></v-spacer>
        <v-btn 
          color="success" 
          rounded 
          elevation="2"
          x-large
          :loading="loading"
          @click="$emit('done', { seconds_spent: seconds_spent, index })"
          :disabled="!enableFulfillment"
          class="action-button px-4"
        >
          <v-icon left>mdi-check</v-icon>
          Complete
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
      
      <div class="text-center pb-4" v-if="(active && !loading) || force_skip">
        <v-btn 
          color="warning" 
          outlined
          rounded 
          @click="$emit('drop')"
          class="skip-button"
        >
          <v-icon left>mdi-package-variant-remove</v-icon>
          {{ order.sf_data.shipping_service && order.sf_data.shipping_service.type === 'shopify' && order.sf_data.labels && order.sf_data.labels.length ? 'Void label and skip order' : 'Skip without fulfilling' }}
        </v-btn>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { LetterFilter } from '@/utils/filters'
import { mapState, mapGetters } from 'vuex'
import ShippingMapCard from '@/components/ShippingMapCard'
import { StreamBarcodeReader } from "vue-barcode-reader";
import print from 'print-js'
import ParcelPhoto from '@/components/ParcelPhoto'
import ShopifyLabelsCard from '@/components/ShopifyLabelsCard'
import ParcelErrorCard from '@/components/ParcelErrorCard.vue';
import ToolTip from '@/components/ToolTip'
import firebase from 'firebase/app'
import 'firebase/storage'

export default {
  components: {
    ShippingMapCard,
    StreamBarcodeReader,
    ParcelPhoto,
    ShopifyLabelsCard,
    ParcelErrorCard,
    ToolTip,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true, 
      default: 0,
    },
    active: {
      type: Boolean, 
      required: true,
      default: false,
    },
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
    show_manual_shipment: {
      type: Boolean, 
      required: true,
      default: false,
    },
    force_skip: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  watch: {
    active: {
      handler: function(active){
        if(!active)return
        if(!this.timer) this.timer = setInterval(() => {
          if(!this.active || !document.hasFocus()) return 
          this.seconds_spent += 1
        }, 1000)
      },  
      immediate: true,
    },
    carrier: function(id){
      if(id && !this.shipping.carriers[id]) this.$store.dispatch('shipping/get_carrier_partners', id)
    }
  },
  computed: {
    ...mapState({
      company: (state) => state.warehouse.company,
      shipping: state => state.shipping,
      photos: state => state.order.photos,
    }),
    ...mapGetters({
      // get_parcel_errors: 'order/get_parcel_errors',
      get_available_methods: 'shipping/get_available_methods',
      get_printing_possible: 'warehouse/get_printing_possible',
      get_printserver_connected: 'warehouse/get_printserver_connected',
      get_product: 'product/get_product',
    }),
    store(){
      return this.company.data_sources.find(i => i.id === this.order.sf_data_source_id)
    },
    activePackingInstructions() {
      if (!this.store || !this.store.packing_instructions) return [];
      
      const instructions = this.store.packing_instructions || [];  
        // Filter instructions based on conditions
        return instructions.filter(instruction => {
          // If no condition, always show
          if (!instruction.condition) return true;
          
          // Check if condition is met
          if (instruction.condition.type === 'parcel-contains-tag') {
            // Check if any product in the order has the specified tag
            const tag = instruction.condition.key;
            return this.order.line_items.some(item => {
              const product = this.get_product(item.uuid);
              console.log(product)
              return product && product.tags && product.tags.includes(tag);
            });
          }
          
          return true;
        });
      
    },
    request_barcode_scan(){
      return this.company.require_item_scanning 
        && this.order.line_items.some(item => !item.is_scanned && this.get_product(item.uuid) && this.get_product(item.uuid).barcode && item.fulfilled_quantity && this.active)
    },
    enableFulfillment(){
      // this.$store.commit('app/SET_SNACK_BAR', this.request_barcode_scan + ' ' + 
      // this.force_skip + ' ' + 
      // this.get_printing_possible + ' ' + 
      // this.company.require_fulfillment_photos + ' ' + 
      // (this.order.sf_data.labels && this.order.sf_data.labels.length))
      if(this.request_barcode_scan) return false
      if(this.force_skip) return false
      if(!this.get_printing_possible) return true
      if(this.company.require_fulfillment_photos && (!this.photos[this.index] || !this.photos[this.index].length)) return false
      return this.order.sf_data.labels && this.order.sf_data.labels.length
    },
    amount_stamps(){
      const breaks = {
        50: { SE: 1, ROW: 2},
        100: { SE: 2, ROW: 3},
        250: { SE: 3, ROW: 6},
        500: { SE: 4, ROW: 8},
        1000: { SE: 6, ROW: 11},
        2000: { SE: 7, ROW: 13},
      }
      for(const i in breaks){
        if(this.order.total_weight < parseInt(i)) return this.order.shipping_address.country_code === 'SE' ? breaks[i].SE : breaks[i].ROW
      }
      return '?'
    },
    storeColorStyle() {
      if (this.store && this.store.color_identifier) {
        // Add 4D to the hex color for 0.3 opacity (30% in hex is 4D)
        return { 'background-color': `${this.store.color_identifier}` };
      }
      return {  }; // Default background color
    }
  },
  filters: {
    LetterFilter,
  },
  data() {
    return {
      carrier: null,
      service: null,
      loading_manual_shipment: false,
      barcode: '',
      show_text: false,
      seconds_spent: 0,
      timer: null,
    }
  },
  methods: {
    manual_submit_barcode(){
      const barcode = this.barcode.replace(/ /gi, '')
      if(!this.order.line_items.find(item => this.get_product(item.uuid).barcode === barcode)) return
      this.$emit('scan_barcode', { barcode, index: this.index })
      this.barcode = ''
    },
    on_scanned_barcode(barcode){
      this.$emit('scan_barcode', { barcode, index: this.index })
    },
    set_method(req){
      this.loading_manual_shipment = true
      this.$emit('update', {...req, index: this.index})
    },
    async openPrint(file_ref){
      const url = await firebase.storage().ref().child(file_ref).getDownloadURL()
      window.open(url, 'new')
    },
    async printWithDialog(file_ref){
      const url = await firebase.storage().ref().child(file_ref).getDownloadURL()
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = () => {
        const blob = xhr.response;
        print({ printable: URL.createObjectURL(blob) })
      };
      xhr.open('GET', url);
      xhr.send();
      print({ printable: url })
    },
    sendToPrint({ pdf_ref, description, media }){ 
      this.$store.dispatch('warehouse/submit_print_job', { 
        pdf_ref, 
        description, 
        media, 
        carrier_id: this.order.sf_data.shipping_service.sf_carrier_account_id 
      })
    },
  },
  beforeDestroy(){
    console.log('on destroy')
    if(this.timer) clearInterval(this.timer)
  }
}
</script>

<style lang="scss" scoped>
.item__title {
  white-space: normal !important;
}

.parcel-card-container {
  max-width: 600px;
  margin: 0 auto;
}

.parcel-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  background-color: white;
  
  &.active-card {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1) !important;
  }
}

.parcel-header {
  background-color: #f9f9fa;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 16px;
}

.parcel-title {
  overflow-x: auto;
  white-space: nowrap;
  max-width: 100%;
  display: block;
}

.parcel-content {
  padding: 16px;
}

.scanner-section {
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.02);
  animation: fadeIn 0.4s ease-out;
}

.barcode-scanner {
  width: 100%;
  max-width: 300px;
  margin: 0 auto 16px;
  border-radius: 8px;
  overflow: hidden;
}

.barcode-input {
  max-width: 300px;
  margin: 0 auto;
}

.labels-section {
  animation: fadeIn 0.4s ease-out;
  display: flex;
  gap: 1em;
  align-items: center;
}

.labels-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 8px;
}

.label-btn {
  transition: all 0.2s ease;
  
  &:hover {
    transform: translateY(-2px);
  }
}

.tracking-number {
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.signature-note {
  display: flex;
  align-items: center;
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.6);
}

.packing-instructions-card,
.items-card {
  // background-color: rgba(0, 0, 0, 0.02);
  transition: all 0.2s ease;
  
  &:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05) !important;
  }
}

.special-instructions {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.instruction-chip {
  transition: all 0.2s ease;
  
  &:hover {
    transform: translateY(-2px);
  }
}

.parcel-actions {
  padding: 16px;
}

.action-button {
  min-width: 160px;
}

.skip-button {
  transition: all 0.2s ease;
  
  &:hover {
    transform: translateY(-2px);
  }
}

/* Animations */
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}

.slide-enter-to, 
.slide-leave {
  max-height: 300px;
  overflow: hidden;
}

.slide-enter, 
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Mobile-specific styles */
@media (max-width: 600px) {
  .parcel-header {
    padding: 12px;
  }
  
  .parcel-content {
    padding: 12px;
  }
  
  .parcel-actions {
    padding: 12px;
  }
  
  .parcel-title {
    font-size: 1.1rem;
  }
}
</style>
