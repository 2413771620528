<template>
  <v-container
    fluid
    tag="section"
    class="px-0 px-md-3 item-card-container"
  >
    <!-- Remove Item Dialog -->
    <v-dialog
      v-model="dialog"
      max-width="500"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      content-class="remove-dialog"
    >
      <v-card rounded="lg" elevation="3">
        <v-card-title class="dialog-header">
          <div class="d-flex align-center">
            <v-icon color="error" class="mr-2">mdi-alert-circle</v-icon>
            <span class="text-h6 font-weight-medium">Remove Item</span>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="dialog-content">
          <v-textarea
            outlined
            rows="2"
            name="reason"
            no-resize
            rounded
            label="For what reason is this item being removed?"
            :rules="[v => v && v.length > 5 || 'Required']"
            v-model="reason"
            class="mb-4"
          />
          
          <!-- Quantity Selection -->
          <template v-if="selected_order_for_removal && get_data_source_by_bucket_order(selected_order_for_removal.order_uuid).allow_partial_fulfillment">
            <p class="text-subtitle-2 font-weight-medium mb-2">
              Of the {{ selected_order_for_removal.quantity - selected_order_for_removal.removed_quantity }} ordered items, how many are you removing?
            </p>
            <div class="quantity-selector">
              <v-btn
                color="error"
                fab
                small
                dark
                @click="removed_quantity--"
                :disabled="removed_quantity <= 1"
                class="quantity-btn"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
              
              <v-text-field
                v-model="removed_quantity"
                :rules="[
                  v => v > 0 || 'Must be greater than 0',
                  v => v <= selected_order_for_removal.quantity - selected_order_for_removal.removed_quantity || 'Must be fewer than ordered quantity'
                ]"
                hide-details
                hide-spin-buttons
                outlined
                rounded
                class="quantity-input text-h4 text-center"
                ref="removed_quantity"
                type="number"
              />
              
              <v-btn
                color="success"
                fab
                small
                dark
                @click="removed_quantity++"
                :disabled="removed_quantity >= selected_order_for_removal.quantity - selected_order_for_removal.removed_quantity"
                class="quantity-btn"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </template>
          
          <!-- Order Info Alert -->
          <v-alert
            type="info"
            outlined
            dense
            v-if="selected_order_for_removal && selected_order_for_removal.order_uuid && get_order_by_uuid(selected_order_for_removal.order_uuid).line_items.length > 1"
            class="mt-4"
          >
            <div class="d-flex align-center">
              <v-icon color="info" class="mr-2">mdi-information</v-icon>
              <span v-if="get_data_source_by_bucket_order(selected_order_for_removal.order_uuid).allow_partial_fulfillment">
                Other items in this order will remain and be partially fulfilled. To change this default behaviour, go to Settings > Stores and flip "Allow partial fulfillment" off.
              </span>
              <span v-else>
                All items on this order will be dropped. If you wish to change this default behaviour, go to Settings > Stores and flip the switch "Allow partial fulfillment" on.
              </span>
            </div>
          </v-alert>
        </v-card-text>

        <v-card-actions class="dialog-actions" v-if="selected_order_for_removal && selected_order_for_removal.order_uuid">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="dialog = false"
            class="mr-2"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            rounded
            elevation="1"
            @click="removeItem({ override_default: false })"
            :disabled="!!($refs.removed_quantity && $refs.removed_quantity.errorBucket.length)"
          >
            <v-icon left>{{ get_data_source_by_bucket_order(selected_order_for_removal.order_uuid).allow_partial_fulfillment ? 'mdi-package-variant-remove' : 'mdi-package-variant-remove' }}</v-icon>
            {{ get_data_source_by_bucket_order(selected_order_for_removal.order_uuid).allow_partial_fulfillment ? 'Remove Item' : 'Remove Item and Drop Order' }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
        
        <div class="text-center pb-4" v-if="selected_order_for_removal && selected_order_for_removal.order_uuid">
          <v-btn
            text
            small
            color="grey darken-1"
            @click="removeItem({ override_default: true })"
            :disabled="!!($refs.removed_quantity && $refs.removed_quantity.errorBucket.length)"
          >
            {{ get_data_source_by_bucket_order(selected_order_for_removal.order_uuid).allow_partial_fulfillment ? 'Remove item and drop order' : 'Remove item and keep order' }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    
    <!-- Main Item Card -->
    <v-card 
      class="item-card"
      :class="{ 'active-card': active }"
      :disabled="!active || section_count"
      rounded="lg"
      elevation="3"
    >
      
      <div class="item-content">
        <!-- Location Information -->
        <div class="location-section" v-if="item.position">
          <div class="location-grid">
            <div class="location-cell">
              <div class="location-label">Shelve</div>
              <div class="location-value">{{ item.position.shelve }}</div>
            </div>
            <div class="location-cell">
              <div class="location-label">Shelf</div>
              <div class="location-value">{{ (item.position.shelf) | LetterFilter }}</div>
            </div>
            <div class="location-cell">
              <div class="location-label">Slot</div>
              <div class="location-value">{{ (item.position.slot) | OneIndexFilter }}</div>
            </div>
            <div class="location-cell">
              <div class="location-label">Product</div>
              <div class="location-value">{{ (item.position.product) | LetterFilter }}</div>
            </div>
          </div>
          <v-btn
            icon
            x-small
            class="edit-btn"
            v-if="get_user_scope('warehouse')"
            @click="$router.push(
              item.position ? 
              `warehouse/shelve/${item.position.shelve_index + 1}?shelf=${item.position.shelf}&slot=${item.position.slot}&product=${item.position.product}` :
              'warehouse'
              )"
          >
            <v-icon>mdi-square-edit-outline</v-icon>
          </v-btn>
        </div>
        
        <!-- Item Name and Quantity -->
        <div class="item-info">
          <h3 :class="{'text-subtitle-1': !!item.position, 'text-h6': !item.position}" class="item-name">
            {{ item.name }}
          </h3>
          <div class="item-quantity">
            {{ item.orders.reduce((tot, order) => tot + order.quantity, 0) }}x
          </div>
        </div> 
        <!-- Item Image -->
        <div class="image-section">
          <v-img
            v-if="get_product(item.uuid) && get_product(item.uuid).image && get_product(item.uuid).image.src"
            :src="get_product(item.uuid).image.src"
            contain
            class="item-image"
          ></v-img>
          <div v-else class="no-image">
            <v-icon size="64" color="grey lighten-1">mdi-image-off</v-icon>
            <div class="text-caption grey--text text--darken-1">No image available</div>
          </div>
        </div>
        <template v-if="is_scanning">
            <transition name="slide">
              <StreamBarcodeReader 
                v-if="active" 
                class="barcode-scanner"
                :class="{ red_border: !qr_error }"
                @decode="on_scanned_barcode" 
              />
            </transition>
            <v-btn 
              color="primary" 
              outlined 
              rounded 
              @click="on_code_not_working" 
              class="mt-3"
            >
              <v-icon left small>mdi-barcode-off</v-icon>
              Code not working
            </v-btn>
          </template>
        <!-- Order Buckets -->
        <div class="orders-section" v-else>
          <div class="orders-grid">
            <div 
              v-for="(order, i) in item.orders" 
              :key="`order-${i}`"
              class="order-cell"
            >
              <v-card 
                class="order-chip"
                :class="{'item-picked': !(order.quantity - order.removed_quantity - order.fulfilled_quantity)}"
                elevation="1"
                rounded
              >
                <div class="order-quantity">
                  <span v-if="order.removed_quantity" class="removed-quantity">{{ order.removed_quantity }}</span>
                  <span>{{ order.quantity - order.removed_quantity }}x</span>
                </div>
                
                <template v-if="company.order_bucket_size > 1">
                  <v-icon small class="mx-1">mdi-arrow-right</v-icon>
                  <span class="order-bucketname">{{ get_order_bucket_position(order.order_uuid) | LetterFilter }}</span>
                </template>
                
                <v-btn
                  color="error"
                  icon
                  x-small
                  absolute
                  class="remove-button"
                  @click.stop="open_dialog(order)"
                >
                  <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
              </v-card>
            </div>
          </div>
        </div>
        
        <!-- Inventory Alert -->
        <v-alert
          class="inventory-alert"
          outlined
          dense
          v-if="!is_scanning && get_product(item.uuid) && get_product(item.uuid).inventory_quantity === 0 && item.last_pick && !count_products && get_plan && get_plan.allow_inventory_count"
        >
          <div class="d-flex align-center justify-space-between flex-wrap">
            <div class="text-body-2">
              <v-icon small color="warning" class="mr-1">mdi-alert</v-icon>
              This should be the last on the shelf.
            </div>
            <v-btn
              small
              color="primary"
              rounded
              @click="set_counting"
              class="mt-2 mt-sm-0"
            >
              <v-icon left small>mdi-pencil</v-icon>
              Not correct
            </v-btn>
          </div>
        </v-alert>
        
        <!-- Action Buttons -->
        <div class="action-section" v-if="!is_scanning">
          <v-btn 
            color="success" 
            dark 
            rounded 
            elevation="2"
            x-large
            @click="doneFulfill()" 
            class="action-button"
          >
            <v-icon left>{{ !count_products ? 'mdi-check' : 'mdi-arrow-down' }}</v-icon>
            {{ !count_products ? 'Done' : 'Continue' }}
          </v-btn>
        </div>
      </div>
      <!-- <input style="width:0px; height: 0px" type="text" v-model="shelf_barcode" ref="barcode" @keydown="on_barcode"/> -->
    </v-card>
    
    <!-- Inventory Count Card -->
    <v-card 
      class="count-card"
      :class="{ 'active-card': section_count && active }"
      v-if="count_products" 
      :disabled="!section_count || !active"
      :id="`countCard-${index}`"
      rounded="lg"
      elevation="3"
    >
      <div class="plan-banner" v-if="get_plan && !get_plan.allow_inventory_count">
        Not available on your current plan
      </div>
      
      <v-card-title class="count-title">
        <v-icon color="primary" class="mr-2">mdi-clipboard-check</v-icon>
        <span class="text-h6 font-weight-medium">Inventory Count</span>
      </v-card-title>
      
      <v-card-text class="count-content">
        <v-alert
          color="grey darken-4"
          outlined
          class="mb-4"
        >
          <div class="text-body-2">
            How many <strong>{{ item.name }}</strong> are left on the shelf, after the above {{ item.orders.length > 1 ? 'orders' : 'order'}} got picked?
          </div>
        </v-alert>
        
        <div class="quantity-selector">
          <v-btn
            color="error"
            fab
            dark
            @click="amount_products--"
            :disabled="amount_products <= 0"
            class="quantity-btn"
          >
            <v-icon>mdi-minus</v-icon>
          </v-btn>
          
          <v-text-field
            v-model="amount_products"
            v-if="get_plan.allow_inventory_count"
            hide-details
            hide-spin-buttons
            outlined
            rounded
            class="quantity-input text-h3 text-center"
            type="number"
          />
          
          <v-text-field
            v-else
            hide-details
            outlined
            rounded
            class="quantity-input text-h3 text-center"
            value="?"
            disabled
          />
          
          <v-btn
            color="success"
            fab
            dark
            @click="amount_products++"
            class="quantity-btn"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
        
        <div class="text-center mt-6">
          <v-btn 
            color="success" 
            dark 
            rounded 
            elevation="2"
            x-large
            @click="doneCount" 
            class="action-button"
          >
            <v-icon left>mdi-check</v-icon>
            Confirm Count
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { OneIndexFilter, LetterFilter } from '@/utils/filters'
import { mapGetters, mapState } from 'vuex'
import { StreamBarcodeReader } from "vue-barcode-reader";
import VueScrollTo from 'vue-scrollto'

export default {
  components: {
    StreamBarcodeReader,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    request_count: {
      type: Boolean,
      required: true,
    }
  },
  computed: {
    ...mapState({
      require_shelf_scanning: state => state.warehouse.company.require_shelf_scanning,
      company: state => state.warehouse.company,
    }),
    ...mapGetters({
      get_order_bucket_position: 'order/get_order_bucket_position',
      get_product: 'product/get_product',
      get_user_scope: 'user/get_user_scope',
      get_plan: 'warehouse/get_plan',
      get_data_source_by_bucket_order: 'order/get_data_source_by_bucket_order',
      get_order_by_uuid: 'order/get_order_by_uuid',
    }),
    is_scanning(){
      return this.require_shelf_scanning && !this.qr_scanned && this.item.position?.printed
    },
    image_dimension(){
      const height = this.standard_height
      const width = this.get_product(this.item.uuid).image && this.get_product(this.item.uuid).image.width 
        ? this.get_product(this.item.uuid).image.width / this.get_product(this.item.uuid).image.height * height
        : height
      return { width, height }
    },
    amount_products: {
      get() {
        return this.get_product(this.item.uuid).inventory_quantity
      },
      set(inventory_quantity){
        this.$store.commit('product/SET_INVENTORY', { inventory_quantity, uuid: this.item.uuid })
      }
    }
  },
  watch: {
    active: {
      handler: function(active){
        if(!active)return
        this.$nextTick(() => this.$refs.barcode?.focus())
        if(!this.timer) this.timer = setInterval(() => {
          if(!this.active || !document.hasFocus()) return 
          this.seconds_spent += 1
        }, 1000)
      },
      immediate: true
    },
    request_count: {
      handler: function(val){
        if(val){ 
          this.count_products = true 
          this.initial_inventory = this.get_product(this.item.uuid).inventory_quantity
        }
      },
      immediate: true,
    }
  },
  filters: {
    OneIndexFilter,
    LetterFilter,
  },
  data() {
    return {
      color: '',
      count_products: false,
      section_count: false,
      initial_inventory: 0,
      dialog: false,
      reason: 'Out of stock',
      standard_height: 150,
      selected_order_for_removal: null,
      qr_error: true,
      qr_scanned: false,
      // shelf_barcode: '',
      removed_quantity: 0,
      seconds_spent: 0,
      timer: null,
    }
  },
  methods: {
    // async on_barcode(t){
    //   if(t.key !== 'Enter') return
    //   else await this.$prompt({
    //     text: 'Bar code: ' + this.shelf_barcode,
    //     buttons: [
    //       {
    //         value: { ok: true },
    //         text: 'OK'
    //       },]
    //   })
    //   this.shelf_barcode = ''
    // },
    async on_code_not_working(){
      const { ok } = await this.$prompt({
        text: 'This will prompt a new label to be printed. Only press this if the code is actually not working.',
        buttons: [
          {
            value: { ok: true },
            text: 'Proceed'
          },
          {
            value: { ok: false },
            text: 'Cancel'
          },
        ]
      })
      if(!ok) return 
      const { shelve_index, shelf, slot, product } = this.item.position
      this.$store.commit('warehouse/SET_MULTIPLE_SLOTS', [{
        shelve_index, shelf, slot, product, printed: false
      }])
      this.$store.dispatch('warehouse/save_shelf')
      this.qr_scanned = true
    },
    open_dialog(order){
      this.selected_order_for_removal = order
      this.removed_quantity = order.quantity - order.removed_quantity
      this.dialog = true
    },
    after_zoom(){
      this.standard_height = this.standard_height === 150 ? 150 : 300
    },
    set_counting(){
      this.count_products = true 
      this.initial_inventory = this.get_product(this.item.uuid).inventory_quantity
    },
    on_scanned_barcode(barcode){
      console.log(barcode);
      if(barcode === this.item.uuid) return this.qr_scanned = true
      else this.qr_error = true
    },
    doneFulfill(){
      if(this.count_products && this.get_plan && this.get_plan.allow_inventory_count){
        setTimeout(() => {
          VueScrollTo.scrollTo(`#countCard-${this.index}`, 500,{
            offset: -100,
          });
        }, 100)
        return this.section_count = true
      }      
      this.$emit('done', {seconds_spent: this.seconds_spent, index: this.index })
    },
    doneCount(){
      this.$store.dispatch('product/update_inventory', {
        uuid: this.item.uuid, 
        change: this.amount_products - this.initial_inventory 
      });
      this.$emit('done', {seconds_spent: this.seconds_spent, index: this.index })
    },
    removeItem({ override_default }){
      this.dialog = false
      this.$store.dispatch('order/remove_item', {
        order: this.selected_order_for_removal, 
        item: this.item, 
        reason: this.reason, 
        override_default, 
        amount: this.removed_quantity 
      })
    },
  },
  beforeDestroy(){
    console.log('on destroy')
    if(this.timer) clearInterval(this.timer)
  }
}
</script>

<style lang="scss" scoped>
.item-card-container {
  max-width: 600px;
  margin: 0 auto;
}

.item-card, 
.count-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: scroll;
  background-color: white;
  height: 85vh; /* Fill most of the screen height */
  display: flex;
  flex-direction: column;
  
  &.active-card {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1) !important;
  }
}

.item-content {
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.location-section {
  margin-bottom: 16px;
}

.location-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  padding: 12px 8px;
}

.location-cell {
  padding: 8px;
  min-width: 60px;
  flex: 1;
}

.location-label {
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 4px;
}

.location-value {
  font-size: 2.5rem; /* Larger font size for better readability */
  font-weight: 700; /* Bolder font for better visibility */
  line-height: 1.2;
  // color: var(--v-primary-base);
}

.item-info {
  text-align: center;
  margin-bottom: 16px;
}

.item-name {
  margin-bottom: 8px;
  word-break: break-word;
}

.item-quantity {
  font-size: 2.5rem;
  font-weight: 700;
  // color: var(--v-primary-base);
}

.image-section {
  flex: 1; /* Take up available space */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  min-height: 1px;
  margin-bottom: 16px;
}

.item-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 8px;
  background-color: white;
}

.no-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.orders-section {
  margin-bottom: 16px;
}

.orders-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
}

.order-cell {
  position: relative;
  flex-grow: 1;
  max-width: 45%;
}

.order-chip {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  position: relative;
  border: 2px solid rgba(0, 0, 0, 0.12);
  height: 60px; /* Fixed height for larger chips */
  
  &.item-picked {
    background-color: rgba(76, 175, 80, 0.1) !important;
    border: 2px solid #4CAF50;
  }
}

.order-quantity {
  display: flex;
  align-items: center;
  font-size: 1.5rem; /* Larger font size */
  font-weight: 600; /* Bolder font */
}

.order-bucketname {
  font-size: 1.5rem; /* Larger font size */
  font-weight: 600; /* Bolder font */
}

.removed-quantity {
  text-decoration: line-through;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.6);
}

.remove-button {
  position: absolute;
  top: -8px;
  right: -8px;
  opacity: 0.8;
  transition: all 0.2s ease;
  
  &:hover {
    opacity: 1;
    transform: scale(1.1);
  }
}

.inventory-alert {
  margin-bottom: 16px;
}

.action-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto; /* Push to bottom */
  padding-top: 16px;
}

.action-button {
  min-width: 160px;
  height: 56px; /* Taller button for easier tapping */
  font-size: 1.1rem; /* Larger font */
}

.barcode-scanner {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  border-radius: 8px;
  overflow: hidden;
}

.red_border {
  border: 2px solid red;
}

.count-card {
  margin-top: 24px;
  position: relative;
  height: auto; /* Override height for count card */
}

.count-title {
  background-color: #f9f9fa;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.count-content {
  padding: 24px;
}

.quantity-selector {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin: 16px 0;
}

.quantity-input {
  max-width: 100px;
  text-align: center;
  
  ::v-deep input {
    text-align: center;
    font-size: 1.5rem;
  }
}

.quantity-btn {
  transition: transform 0.2s ease;
  
  &:hover {
    transform: scale(1.1);
  }
}

.plan-banner {
  position: absolute;
  left: 0;
  width: 100%;
  top: 40%;
  font-size: 2em;
  transform: rotate(-25deg);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  text-align: center;
  padding: 8px;
  z-index: 1;
}

.edit-btn {
  position: absolute;
  right: 10px;
  top: 8px;
}

.dialog-header {
  background-color: #f9f9fa;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 16px 24px;
}

.dialog-content {
  padding: 24px;
}

.dialog-actions {
  padding: 16px 24px 8px;
}

/* Animations */
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}

.slide-enter-to, 
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter, 
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

/* Mobile-specific styles */
@media (max-width: 600px) {
  .location-value {
    font-size: 2.25rem; /* Still large but slightly smaller for mobile */
  }
  
  .item-quantity {
    font-size: 2.25rem;
  }
  
  .order-chip {
    padding: 10px 14px;
    height: 55px;
  }
  
  .order-quantity {
    font-size: 1.35rem;
  }
  
  .dialog-header {
    padding: 12px 16px;
  }
  
  .dialog-content {
    padding: 16px;
  }
  
  .dialog-actions {
    padding: 12px 16px 8px;
  }
  
  .order-cell {
    max-width: 48%;
  }
}
</style>
