<template>
  <div class="parcel-photo-container">
    <template v-if="!photo_error">
      <div class="camera-container">
        <!-- Camera View or Selected Photo -->
        <video 
          v-if="selected_photo === null"
          ref="camera"
          class="camera-view"
          muted
          playsinline
        ></video>
        
        <v-img
          v-else
          :src="to_data_url(photos[order_index][selected_photo])"
          class="photo-preview"
          contain
        />
        
        <canvas ref="canvas" class="hidden-canvas"></canvas>
        
        <!-- Camera Controls -->
        <div class="camera-controls">
          <!-- Take Photo Button -->
          <v-btn
            v-if="selected_photo === null && camera_started"
            fab
            color="error"
            x-large
            elevation="3"
            class="capture-button"
            @click="take_photo"
          >
            <v-icon color="white" x-large>mdi-camera</v-icon>
          </v-btn>
          
          <!-- Delete Photo Button -->
          <v-btn
            v-else-if="selected_photo !== null"  
            fab
            color="error"
            elevation="3"
            class="delete-button"
            @click="delete_photo"
          >
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </div>
        
        <!-- Top Controls -->
        <div class="top-controls">
          <!-- Close Image Button -->
          <v-btn
            v-if="selected_photo !== null"
            fab
            color="grey darken-3"
            small
            elevation="2"
            @click="close_image"
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
          
          <!-- Switch Camera Button -->
          <v-btn 
            v-else-if="show_camswitch"
            fab
            color="grey darken-3"
            small
            elevation="2"
            @click="switch_camera"
          >
            <v-icon color="white">mdi-camera-flip</v-icon>
          </v-btn>
        </div>
      </div>
      
      <!-- Photo Thumbnails -->
      <div class="thumbnails-container">
        <div 
          v-for="(photo, index) in (photos[order_index] || [])"
          :key="index"
          class="thumbnail-wrapper"
        >
          <v-img
            :src="to_data_url(photo)"
            contain
            class="thumbnail-image"
            @click="select_photo(index)"
          ></v-img>
          
          <v-btn
            fab
            x-small
            color="grey darken-3"
            class="thumbnail-delete-btn"
            @click.stop="delete_photo(index)"
          >
            <v-icon color="white" x-small>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
    </template>
    
    <!-- Error State -->
    <template v-else>
      <v-alert
        type="error"
        outlined
        class="error-alert"
      >
        <div class="d-flex align-center">
          <v-icon color="error" class="mr-2">mdi-camera-off</v-icon>
          <span>Your company requires parcel photos but your camera is unavailable. Make sure to allow access to your camera or turn off parcel photos.</span>
        </div>
      </v-alert>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    order_index: {
      type: Number, 
      required: true,
    }
  },
  data() {
    return {
      picture: null,
      selected_photo: null,
      show_camswitch: true,
      camera_started: false,
      video: null,
      camera_faces_user: false,
      photo_error: false,
    }
  },
  computed: {
    ...mapState({
      photos: state => state.order.photos
    })
  },
  methods: {
    delete_photo(photo_index) {
      this.$store.commit('order/REMOVE_PHOTO', {
        order_index: this.order_index, 
        photo_index: this.selected_photo !== null ? this.selected_photo : (photo_index || 0)
      })
      if (this.selected_photo !== null) this.close_image()
    },
    select_photo(index) {
      this.selected_photo = index
    },
    to_data_url(blob) {
      return blob && URL.createObjectURL(blob)
    },
    take_photo() {
      // navigator.vibrate(20)
      const canvas = this.$refs.canvas
      const video = this.$refs.camera
      const context = canvas.getContext('2d');
      
      // Use the actual video dimensions instead of client dimensions for better quality
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      
      // Use JPEG with high quality (0.95) for better quality/size balance
      canvas.toBlob(photo => {
        this.$store.commit('order/SET_PHOTOS', {
          order_index: this.order_index, 
          photo
        })
      }, 'image/jpeg', 0.95)
    },
    close_image() {
      this.selected_photo = null
      this.start_camera()
    },
    start_camera() {
      this.stop_camera()
      navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: this.camera_faces_user ? 'user' : 'environment',
          width: { ideal: 1920 },
          height: { ideal: 1080 },
          aspectRatio: { ideal: 4/3 },
          // Request highest quality available
          frameRate: { ideal: 30 },
          resizeMode: 'none'
        }
      })
      .then(stream => {
        this.video = this.$refs.camera
        this.video.srcObject = stream
        this.video.onloadedmetadata = () => {
          this.video.play();
          this.camera_started = true
          console.log(`Camera started with resolution: ${this.video.videoWidth}x${this.video.videoHeight}`);
        };
      })
      .catch((error) => {
        console.error('Camera error:', error);
        this.photo_error = true;
      })
    },
    stop_camera() {
      return this.video && this.video.srcObject && this.video.srcObject.getTracks().map(t => t.stop());
    },
    switch_camera() {
      this.camera_faces_user = !this.camera_faces_user
      this.start_camera()
    },
    async log_cameras() {
      if (this.$store.state.app.logged_camera) return
      
      const cameras = []
      if (navigator.mediaDevices?.enumerateDevices) {
        const devices = await navigator.mediaDevices.enumerateDevices()
        for (const device of devices) {
          if (device.kind !== 'videoinput') { 
            cameras.push({ kind: device.kind }); 
            continue 
          }
          
          const camera = {device, capabilities: [], settings: []}
          const stream = await navigator.mediaDevices.getUserMedia({ 
            video: { deviceId: { exact: device.deviceId } } 
          });
          
          stream.getVideoTracks().forEach(track => {
            const capabilities = track.getCapabilities();
            camera.capabilities.push(capabilities)
            const settings = track.getSettings();
            camera.settings.push(settings)
          })
          
          stream.getTracks().forEach(track => track.stop());
          cameras.push(camera)
        }
        
        this.$store.commit('app/SET_STATE', {logged_camera: true})
        this.$store.dispatch('app/submit_log', { 
          message: `Camera log\n\nUser: ${this.$store.state.user.userProfile.name}\n\n${JSON.stringify(cameras, null, 3)}`
        })
      }
    }
  },
  beforeDestroy() {
    this.stop_camera()
  },
  async mounted() {
    await this.log_cameras()
    this.start_camera({ facingMode: 'environment' })
  }
}
</script>

<style lang="scss" scoped>
.parcel-photo-container {
  margin-bottom: 16px;
}

.camera-container {
  position: relative;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  background-color: #000;
  margin-bottom: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.camera-view {
  width: 100%;
  height: auto;
  display: block;
  aspect-ratio: 4/3;
  object-fit: cover;
}

.photo-preview {
  width: 100%;
  height: auto;
  aspect-ratio: 4/3;
  background-color: #f5f5f5;
}

.hidden-canvas {
  display: none;
}

.camera-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  z-index: 2;
}

.capture-button {
  width: 72px !important;
  height: 72px !important;
  transition: transform 0.2s ease;
  
  &:hover {
    transform: scale(1.05);
  }
  
  &:active {
    transform: scale(0.95);
  }
}

.delete-button {
  transition: transform 0.2s ease;
  
  &:hover {
    transform: scale(1.05);
  }
}

.top-controls {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 2;
}

.thumbnails-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.thumbnail-wrapper {
  position: relative;
  width: calc(20% - 8px);
  aspect-ratio: 1/1;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
}

.thumbnail-image {
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: #f5f5f5;
}

.thumbnail-delete-btn {
  position: absolute;
  top: 2px;
  right: 2px;
  opacity: 0.8;
  
  &:hover {
    opacity: 1;
  }
}

.error-alert {
  margin: 16px 0;
}

/* Mobile-specific styles */
@media (max-width: 600px) {
  .capture-button {
    width: 64px !important;
    height: 64px !important;
  }
  
  .thumbnail-wrapper {
    width: calc(25% - 8px);
  }
}
</style>
